import { Card } from '@nike/eds'
import { useGetFlagQuery } from 'api/feature-toggle'
import { LoadingOverlay } from 'components/loading-overlay'
import { ScanTote } from 'pages/scan-tote'
import { ScanUnit } from 'pages/scan-unit'
import { type ReactElement } from 'react'
import { Screen } from 'types'

import { CgradeCompletion } from './cgrade-completion'
import { Grading } from './grading'
import { Rework } from './rework'
import { ScanRlpnRasn } from './rlpn-rasn'
import { useUnitHandlingApi } from './useUnitHandlingApi'
import './UnitHandling.css'

export const UnitHandling = () => {
  const {
    defaultUnit,
    unitData,
    setUnitData,
    rasnData,
    goodsHolder,
    activeScreen,
    setActiveScreen,
    processedQuantity,
    openRlpnContext,
    createRasnContext,
    createUnitContext,
    updateUnitGradeContext,
    updateUnitToteScanContext,
    printLabelContext,
    confirmCgradeComplete,
    closeGoodsHolderContext,
    resetToScanRlpnRasn,
    displayFailure
  } = useUnitHandlingApi()

  const { data: canUseHash } = useGetFlagQuery('hash')
  const { data: canUseBlindLpn } = useGetFlagQuery('blind_ilpn')
  const { data: canUseBgrade } = useGetFlagQuery('b°grade')

  const screens: { [key in Screen]: ReactElement } = {
    [Screen.SCAN_RLPN_RASN]: (
        <ScanRlpnRasn
            openRlpnContext={openRlpnContext}
            createRasnContext={createRasnContext}
        />
    ),
    [Screen.SCAN_UNIT]: (
        <ScanUnit
            goodsHolder={goodsHolder}
            rasnData={rasnData!}
            processedQuantity={processedQuantity}
            createUnitContext={createUnitContext}
            finishRasnContext={resetToScanRlpnRasn}
            closeGoodsHolderContext={closeGoodsHolderContext}
            handlePauseCarton={resetToScanRlpnRasn}
            showBlindIlpn={canUseBlindLpn ?? false}
        />
    ),
    [Screen.GRADING]: (
        <Grading
            unitData={unitData}
            rasnChannel={rasnData?.channel}
            handleReturn={() => {
              setActiveScreen(Screen.SCAN_UNIT)
              setUnitData(defaultUnit)
            }}
            updateUnitGradeContext={updateUnitGradeContext}
            useHash={canUseHash ?? false}
            useBgrade={canUseBgrade ?? false}
        />
    ),
    [Screen.REWORK]: (
        <Rework
            unitData={unitData}
            handleReturn={() => {
              setActiveScreen(Screen.GRADING)
            }}
            handleContinue={() => {
              setActiveScreen(Screen.SCAN_TOTE)
            }}
            printLabelContext={printLabelContext}
        />
    ),
    [Screen.SCAN_TOTE]: (
        <ScanTote
            unitData={unitData}
            handleReturn={() => {
              setActiveScreen(Screen.REWORK)
            }}
            updateUnitToteScanContext={updateUnitToteScanContext}
        />
    ),
    [Screen.CGRADE_COMPLETION]: (
        <CgradeCompletion
            unitData={unitData}
            handleReturn={() => {
              setActiveScreen(Screen.GRADING)
            }}
            updateUnitCgradeCompleteContext={confirmCgradeComplete}
            printLabelContext={printLabelContext}
        />
    )
  }

  return (
    <>
      <LoadingOverlay
          isLoading={
              openRlpnContext.showLoadingOverlay ||
              createRasnContext.showLoadingOverlay ||
              createUnitContext.showLoadingOverlay ||
              updateUnitGradeContext.showLoadingOverlay ||
              updateUnitToteScanContext.showLoadingOverlay ||
              closeGoodsHolderContext.showLoadingOverlay
          }
      />
      {displayFailure && <div id="modal_failure" className="modal_failure" />}
      <Card className="alignMiddle">
        {screens[activeScreen]}
      </Card>
    </>
  )
}
